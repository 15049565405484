import {AppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {Container, CssBaseline, LinearProgress, Paper} from "@mui/material";
import i18n from "i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import {Suspense} from "react";
import {Helmet} from "react-helmet";
import {initReactI18next} from "react-i18next";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {useAppInsights} from "../../appInsights";
import ThemeContext from "../../ThemeContext";
import Index from "../page/Index";
import GlobalProvider, {useGlobal} from "./GlobalProvider";
import ProviderPage from "./ProviderPage";

i18n
  .use(I18NextXhrBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "em", "b", "i", "p", "dl", "dt", "dd", "ul", "ol", "li"]
    }
  });

function App(): JSX.Element {
  const {
    favicon,
    analytics: {appName, instrumentationKey}
  } = useGlobal();
  const reactPlugin = useAppInsights({appName, instrumentationKey});
  return (
    <Suspense fallback={<LinearProgress />}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <GlobalProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Adhoc Payments</title>
            <link rel="icon" href={favicon} type="image/svg+xml"></link>
          </Helmet>
          <CssBaseline />
          <ThemeContext>
            <Paper elevation={0} square>
              <Container
                sx={{
                  display: "flex",
                  height: "100vh"
                }}
              >
                <BrowserRouter>
                  <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="/:provider/*" element={<ProviderPage />} />
                  </Routes>
                </BrowserRouter>
              </Container>
            </Paper>
          </ThemeContext>
        </GlobalProvider>
      </AppInsightsContext.Provider>
    </Suspense>
  );
}

export default App;
