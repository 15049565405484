import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app/App";
import {configByProvider} from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const matchers: [RegExp, (m: RegExpMatchArray) => string | undefined][] = [
  // tuples of regexp to match location url with  and funtion to take match result and return provider slug if matched
  [/\/\/localhost.*/, (m) => process.env.REACT_APP_PROVIDER], // local host will provide a providerSlug as env
  [/\/\/apply(?:-\w+)?\.(ichm)(?:-\w+)?\.edu\.au(?::\d+)?/, (m) => m[1]] // Provider in host
];
const provider = matchers
  .map(([patt, toSlug]) => {
    const m = window.location.href.match(patt);
    return m && toSlug(m);
  })
  .find((provider) => !!provider && provider in configByProvider);

/**
 * If provider is known from host/hardcoded, redirect to /<provider>
 */
if (provider) document.location.replace(`/${provider}`);



const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
