import {useAppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {UpApi} from "../../upApi";

export function ResolveDuplicates({
  error,
  onComplete
}: {
  error: UpApi.CreateAdhocStripeSession.DuplicateError;
  onComplete: (studentId: string) => void;
}): JSX.Element {
  const {t} = useTranslation();
  const appInsights = useAppInsightsContext();
  const {
    response: {
      data: {detail: duplicates}
    }
  } = error;
  return (
    <>
      <DialogTitle>{t("ResolveDuplicates.title")}</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id="student-group">{t("ResolveDuplicates.label")}</FormLabel>
          <RadioGroup
            row
            aria-labelledby="student-group"
            onChange={(e, studentId) => {
              appInsights.trackEvent({name: "UI/ResolvedDuplicates"}, {from: duplicates, to: studentId});
              onComplete(studentId);
            }}
          >
            {duplicates.map(({contactid, firstname, lastname}, i) => {
              return <FormControlLabel key={i} value={contactid} control={<Radio required />} label={`${firstname} ${lastname}`} />;
            })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
    </>
  );
}
