import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  TextField
} from "@mui/material";
import axios from "axios";
import {merge} from "lodash";
import React, {Fragment, useEffect, useReducer, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as BankTransfer} from "../../icons/bank-transfer-icon.svg";
import {ReactComponent as Amex} from "../../icons/card-logo-amex.svg";
import {ReactComponent as Jcb} from "../../icons/card-logo-jcb.svg";
import {ReactComponent as MasterCard} from "../../icons/card-logo-mastercard.svg";
import {ReactComponent as Visa} from "../../icons/card-logo-visa.svg";
import {Payment, PaymentMethod, PaymentSession} from "../../payment";
import {UpApi} from "../../upApi";
import {useGlobal} from "../app/GlobalProvider";
import {ResolveDuplicates} from "./ResolveDuplicates";

type ChangeFieldAction = {
  type: "ChangeField";
  key: keyof Payment;
  value: Payment[keyof Payment];
};

type FinalizeAction = {
  type: "Finalize";
  serviceFeeRate: number;
};

type Action = ChangeFieldAction | FinalizeAction;

const reducer: React.Reducer<Partial<Payment> | Payment, Action> = function (state, action) {
  switch (action.type) {
    case "ChangeField": {
      const {key, value}: ChangeFieldAction = action;
      return {...state, [key]: value};
    }
    case "Finalize": {
      const {serviceFeeRate = 0}: FinalizeAction = action;
      const {amount = 0} = state;
      const serviceFee = Math.round((amount * serviceFeeRate) / 100);
      const totalAmount = amount + serviceFee;
      return {...state, serviceFeeRate, serviceFee, totalAmount, finalized: true} as Payment;
    }
    default:
      throw new Error();
  }
};

export default function PaymentDetails({onComplete}: {onComplete: (p: Payment, session: PaymentSession) => void}): JSX.Element {
  const {
    upApi: {baseUri},
    provider,
    paymentMethods,
    component: {PaymentDetails}
  } = useGlobal();
  const {t, i18n} = useTranslation();
  const [payment, dispatch] = useReducer(reducer, {currency: "NZD"});
  const {paymentMethod} = payment || {};
  const {minAmountCents, maxAmountCents, surcharge, isoCurrencyCode} = merge(
    {},
    PaymentDetails,
    paymentMethod && PaymentDetails.optionsByMethod[paymentMethod]
  );
  const minAmount = minAmountCents / 100,
    maxAmount = maxAmountCents / 100;
  function changeField(key: keyof Payment, value: Payment[keyof Payment]): void {
    dispatch({type: "ChangeField", key, value});
  }

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(undefined as {field?: string; message: string} | undefined);
  const {field: errorField, message: errorMessage} = error || {};
  const [resolveDialog, setResolveDialog] = useState(undefined as (() => JSX.Element) | undefined);

  function handleSubmit(payment: Partial<Payment>) {
    const {paymentMethod} = payment;
    const serviceFeeRate = (paymentMethod && surcharge) || 0;
    dispatch({type: "Finalize", serviceFeeRate});
  }
  useEffect(() => {
    const {finalized} = payment || {};
    if (finalized) {
      (async () => {
        const finalPayment: Payment = payment as Payment;
        const {
          paymentMethod,
          studentId,
          paymentReference: invoiceId,
          description: name = "Adhoc payment",
          amount: unitAmountCents,
          serviceFeeRate,
          serviceFee
        } = finalPayment;
        try {
          setError(undefined);
          setBusy(true);
          const lineItems: UpApi.CreateAdhocStripeSession.Body["lineItems"] = [{name, unitAmountCents, quantity: 1}];
          if (serviceFee && serviceFee > 0)
            lineItems.push({
              name: "Service Fee",
              unitAmountCents: serviceFee,
              quantity: 1,
              description: `Charged at ${serviceFeeRate}% for ${paymentMethod} payment`
            });
          const {origin} = document.location;
          const body: UpApi.CreateAdhocStripeSession.Body = {
            callbackUrls: {
              approved: `${origin}/${provider}/complete/approved`,
              cancelled: `${origin}/${provider}/complete/cancelled`,
              declined: `${origin}/${provider}/complete/declined`
            },
            description: name,
            isoCurrencyCode,
            invoiceId: invoiceId || studentId,
            lineItems,
            paymentMethod,
            provider
          };

          const {data: session} = await axios.post<UpApi.CreateAdhocStripeSession.Response>(
            `${baseUri}/v1/student/${studentId}/stripeSession`,
            body
          );
          onComplete(finalPayment, session);
        } catch (e: any) {
          if (axios.isAxiosError(e)) {
            const axiosError = e as UpApi.ErrorResponse;
            switch (axiosError.response.status) {
              case 409:
                setResolveDialog(
                  <ResolveDuplicates
                    error={axiosError as UpApi.CreateAdhocStripeSession.DuplicateError}
                    onComplete={(studentId) => {
                      changeField("studentId", studentId);
                      changeField("finalized", true);
                      setResolveDialog(undefined);
                    }}
                  />
                );
                break;
              default:
                setError(axiosError.response.data);
                break;
            }
          } else setError({message: e.toString()});
          changeField("finalized", false);
        } finally {
          setBusy(false);
        }
      })();
    }
  }, [baseUri, isoCurrencyCode, onComplete, payment, provider]);

  useEffect(() => {
    if (paymentMethods && paymentMethods.length === 1) changeField("paymentMethod", paymentMethods[0]); // only one to choose from
  }, [paymentMethods]);

  const cardIcon = {
    width: "2em",
    margin: "2px"
  };
  const iconsByPaymentMethod: Partial<Record<PaymentMethod, JSX.Element | JSX.Element[]>> = {
    card: [<Visa style={cardIcon} />, <MasterCard style={cardIcon} />, <Amex style={cardIcon} />, <Jcb style={cardIcon} />],
    au_becs_debit: <BankTransfer style={cardIcon} />
  };

  return (
    <Box sx={{maxWidth: "30em"}}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(payment);
          return false;
        }}
      >
        <Grid container direction="column" spacing={1.5} alignItems="stretch">
          <Grid item>
            <TextField
              error={errorField === "studentId"}
              helperText={errorField === "studentId" ? errorMessage : "Sequence of digits"}
              fullWidth
              required
              onChange={({target: {value: studentId}}) => changeField("studentId", studentId)}
              label={t("PaymentDetails.studentId.label")}
              inputProps={{pattern: "\\d+"}}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              onChange={({target: {value: description}}) => changeField("description", description)}
              label={t("PaymentDetails.description.label")}
              helperText={t("PaymentDetails.description.help")}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              onChange={({target: {value: paymentReference}}) => changeField("paymentReference", paymentReference)}
              label={t("PaymentDetails.invoiceId.label")}
              helperText={t("PaymentDetails.invoiceId.help")}
            />
          </Grid>
          {paymentMethods && paymentMethods.length > 1 && (
            <Grid item>
              <FormControl>
                <FormLabel id="method-group">{t("PaymentDetails.method.label")}</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="method-group"
                  onChange={(e, method) => {
                    changeField("paymentMethod", method as PaymentMethod);
                  }}
                >
                  {paymentMethods.map((paymentMethod, i) => {
                    const icons = iconsByPaymentMethod[paymentMethod];
                    return (
                      <FormControlLabel
                        key={i}
                        sx={{"& *": {verticalAlign: "middle"}}}
                        value={paymentMethod}
                        control={<Radio required />}
                        label={
                          <span>
                            {!Array.isArray(icons) && icons}
                            {t(`PaymentDetails.stripePaymentMethods.${paymentMethod}`)}
                            {Array.isArray(icons) && (
                              <div>
                                {icons.map((icon, i) => (
                                  <Fragment key={i}>{icon}</Fragment>
                                ))}
                              </div>
                            )}
                          </span>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          <Grid item>
            <TextField
              fullWidth
              required
              onChange={({target: {value: amount}}) => {
                const amountCents = Math.round(Number.parseFloat(amount) * 100);
                if (amountCents >= minAmountCents && amountCents <= maxAmountCents) {
                  changeField("amount", amountCents);
                  setError(undefined);
                } else {
                  const direction = amountCents > maxAmountCents ? "over" : "under";
                  const key =
                    paymentMethod && i18n.exists(`PaymentDetails.amount.error.${direction}.${paymentMethod}`)
                      ? `PaymentDetails.amount.error.${direction}.${paymentMethod}`
                      : `PaymentDetails.amount.error.${direction}.default`;
                  setError({
                    field: "amount",
                    message: t(key, {maxAmount, minAmount, currency: isoCurrencyCode.toLocaleUpperCase()})
                  });
                }
              }}
              label={t("PaymentDetails.amount.label")}
              type="number"
              InputProps={{startAdornment: "$"}}
              inputProps={{
                min: minAmount,
                max: maxAmount,
                step: "any"
              }}
              error={errorField === "amount"}
              helperText={
                errorField === "amount"
                  ? errorMessage
                  : t("PaymentDetails.amount.help", {maxAmount, minAmount, currency: isoCurrencyCode.toLocaleUpperCase()})
              }
            />
          </Grid>
          <Grid item sx={{textAlign: "center"}}>
            <LoadingButton type="submit" disabled={!!error} loading={busy} variant="contained" startIcon={<PaymentTwoToneIcon />}>
              {t("PaymentDetails.submit.label")}
            </LoadingButton>
          </Grid>
        </Grid>
        <Dialog open={!!resolveDialog}>{resolveDialog}</Dialog>
        <Snackbar
          open={!!error && !errorField}
          onClose={() => setError(undefined)}
          anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        >
          <Alert onClose={() => setError(undefined)} severity="error" sx={{width: "100%"}}>
            {error && errorMessage}
          </Alert>
        </Snackbar>
      </form>
    </Box>
  );
}
