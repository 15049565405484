import {ThemeOptions} from "@mui/material";
import React, {useMemo} from "react";
import {useGlobal} from "./components/app/GlobalProvider";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {common} from "@mui/material/colors";
import {red, grey, green, orange, lightBlue} from "@mui/material/colors";

const {black, white} = common;

const defaultTheme: ThemeOptions = {
  palette: {
    mode: "light",
    success: green,
    warning: orange,
    error: red,
    info: lightBlue
  }
}
const themesByProvider: {
  [key: string]: ThemeOptions;
} = {
  up: {
    palette: {
      primary: {
        main: "#ea4f3d"
      }
    }
  },
  ichm: {
    typography: {
      fontFamily: "GraphickWide",
      h1: {fontFamily: "GraphickWideBold", color: "#000070", textTransform: "uppercase"},
      h2: {fontFamily: "GraphickWideBold", color: "#000070"},
      h3: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h4: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h5: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      h6: {fontFamily: "GraphickWideBold", color: "#ED4036"},
      body1: {fontFamily: "Graphick"},
      body2: {fontFamily: "Graphick"},
      caption: {fontFamily: "Graphick"},
      subtitle1: {fontFamily: "Graphick"},
      subtitle2: {fontFamily: "Graphick"}
    },
    palette: {
      primary: {main: "#000070"},
      secondary: {main: "#ED4036"},
      text: {primary: "#000000", secondary: "#A6A6A6"},
      background: {
        default: white,
        paper: white
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@global": {
            "@font-face": {
              fontFamily: "GraphickWide",
              src: 'url("/providers/ichm/GraphikWide-Regular-Web.woff2") format("woff2")'
            }
          }
        }
      }
    }
  },
  yoobee: {
    palette: {
      primary: {main: "#00B57E"},
      secondary: red
    }
  },
  "face-and-beauty": {
    palette: {
      primary: {main: "#ed1748"},
      secondary: {main: black},
      background: {
        default: white
      }
    }
  },
  tcc: {
    palette: {
      primary: {main: "#00a650"},
      secondary: {main: white},
      text: {primary: black, secondary: grey[900]},
      background: {
        default: white
      }
    }
  },
  nzcm: {
    palette: {
      primary: {main: "#6d8a34"},
      secondary: {main: "#575756"},
      text: {primary: black, secondary: grey[900]},
      background: {
        default: "#252E33"
      }
    }
  },
  nzma: {
    palette: {
      primary: {main: "#009FE3"},
      secondary: {main: "#14699E"}
    }
  },
  nzis: {
    palette: {
      primary: {main: "#ec8d20"},
      secondary: {main: "#14699E"},
      background: {
        default: "#252E33"
      }
    }
  },
  nzst: {
    palette: {
      primary: {main: "#FF29B6"},
      secondary: {main: "#b1b1b1"},
      background: {
        default: white,
        paper: white
      }
    }
  },
  elite: {
    palette: {
      mode: "dark",
      primary: {main: "#D3B987"},
      secondary: {main: "#14699E"},
      text: {primary: grey["A100"], secondary: grey["A100"]},
      background: {
        default: black,
        paper: black
      }
    }
  },
  "cut-above": {
    palette: {
      mode: "dark",
      primary: {main: "#F89307"},
      secondary: {main: white},
      text: {primary: white, secondary: grey[400]},
      background: {
        default: black,
        paper: "#3c3c3b"
      }
    }
  },
  hanz: {
    typography: {
      fontFamily: "UntitledSans"
    },
    palette: {
      primary: {main: "#4BB28A"},
      secondary: {main: "#ab91ce"},
      background: {
        paper: "#e9e9df",
        default: "#e9e9df"
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          "@global": {
            "@font-face": {
              fontFamily: "UntitledSans",
              src: 'url("/providers/hanz/UntitledSans-Regular.otf") format("opentype")'
            }
          }
        }
      }
    }
  }
};

export default function ThemeContext({children}: {children?: React.ReactNode}): JSX.Element {
  const {provider} = useGlobal();
  const theme = useMemo(() => createTheme(provider ? themesByProvider[provider]: defaultTheme), [provider]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
