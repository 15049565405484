import { Box } from "@mui/material";
import { useState } from "react";
import { Payment, PaymentSession } from "../../payment";
import { useGlobal } from "../app/GlobalProvider";
import PaymentDetails from "../section/PaymentDetails";
import StartPayment from "../section/StartPayment";



export default function PaymentPage() {
  const [{payment, session}, setPaymentDetails] = useState<{payment?: Payment; session?: PaymentSession}>({});
  const {
    component: {
      App: {showStartPayment}
    }
  } = useGlobal();

  return (
    <Box sx={{margin: "auto"}}>
      {!(payment && session) && (
        <PaymentDetails
          onComplete={(payment, session: PaymentSession) => {
            if (showStartPayment) setPaymentDetails({payment, session});
            else if (session.url) {
              // Redirect straight to stripe
              document.location.replace(session.url);
            }
          }}
        />
      )}
      {payment && session && <StartPayment payment={payment} session={session} />}
    </Box>
  );
}
