import { PaymentsTwoTone, RestartAltTwoTone } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Fab, Grid } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Stripe } from "../../icons/stripe.svg";
import { Payment, PaymentSession } from "../../payment";
import Currency from "../utility/Currency";

export default function StartPayment({
  payment: {
    studentId,
    paymentReference,
    amount,
    description,
    paymentMethod: method,
    currency,
    serviceFee,
    serviceFeeRate,
    totalAmount
  },
  session: {url}
}: {
  payment: Payment;
  session: PaymentSession;
}) {
  const {t} = useTranslation();
  return (
    <Box>
      <Alert
        variant="outlined"
        elevation={2}
        icon={<PaymentsTwoTone fontSize="large" />}
        sx={{"& table th": {textAlign: "right"}, width: "100%"}}
      >
        <AlertTitle>{t("StartPayment.title")}</AlertTitle>
        <table>
          <tbody>
            <tr>
              <th>{t("StartPayment.studentId")}</th>
              <td>{studentId}</td>
            </tr>
            <tr>
              <th>{t("StartPayment.invoiceId")}</th>
              <td>{paymentReference}</td>
            </tr>
            {serviceFee > 0 && (
              <>
                <tr>
                  <th>{t("StartPayment.serviceFee", {serviceFeeRate})}</th>
                  <td>
                    <Currency currency={currency} value={serviceFee} />
                  </td>
                </tr>
                <tr>
                  <th>{t("StartPayment.amount")}</th>
                  <td>
                    <Currency currency={currency} value={amount} />
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th>{t("StartPayment.totalAmount")}</th>
              <td>
                <Currency currency={currency} value={totalAmount} />
              </td>
            </tr>
            <tr>
              <th>{t("StartPayment.description")}</th>
              <td>{description}</td>
            </tr>
            <tr>
              <th>{t("StartPayment.method")}</th>
              <td>{method}</td>
            </tr>
          </tbody>
        </table>
        {url && (
          <Grid container sx={{p: 1}} justifyContent="center">
            <QRCodeSVG value={url} />
          </Grid>
        )}
      </Alert>
      <Box sx={{"& > :not(style)": {m: 1, textAlign: "right"}}}>
        <Fab color="secondary" variant="extended" onClick={() => document.location.reload()}>
          <RestartAltTwoTone />
          {t("StartPayment.actions.startOver")}
        </Fab>
        {url && (
          <Fab color="primary" variant="extended" href={url}>
            <Stripe style={{height: "2rem"}} />
            {t("StartPayment.actions.payNow")}
          </Fab>
        )}
      </Box>
    </Box>
  );
}
