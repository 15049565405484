/**
 *
 * Render a currency value with configurable styles. Root component is Typography
 */

import {Typography} from "@mui/material";
import rawCurrencies from "./currencies.json";

type Currencies = {
  [key: string]: {
    symbol: string;
    name: string;
    symbol_native: string;
    decimal_digits: number;
    rounding: number;
    code: string;
    name_plural: string;
  };
};
const currencies = rawCurrencies as Currencies;
/**
 * Helper to find currency info (exported as a util)
 * @param currency to lookup from code,name or plural name
 */
export function findCurrency({
  currencyCode,
  currencyName,
  currencyNamePlural
}: {
  currencyCode?: string;
  currencyName?: string;
  currencyNamePlural?: string;
}) {
  return currencyCode
    ? currencies[currencyCode]
    : Object.values(currencies).find(({name, name_plural}) => currencyName === name || currencyNamePlural === name_plural);
}

const Currency = ({
  value,
  valueInCents = true,
  currency,
  currencyName,
  currencyNamePlural,
  formatOptions,
  notation = "standard",
  currencyDisplay = "narrowSymbol",
  signDisplay = "auto",
  ...other
}: {
  value: number;
  valueInCents?: boolean;
  currency?: string;
  currencyName?: string;
  currencyNamePlural?: string;
  formatOptions?: Intl.NumberFormatOptions;
  notation?: Intl.NumberFormatOptions["notation"];
  currencyDisplay?: Intl.NumberFormatOptions["currencyDisplay"];
  signDisplay?: Intl.NumberFormatOptions["signDisplay"];
}): JSX.Element => {
  const parts = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: (
      findCurrency({
        currencyCode: currency,
        currencyName,
        currencyNamePlural
      }) || {code: "AUD"}
    ).code,
    notation,
    currencyDisplay,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay,
    ...formatOptions
  }).formatToParts(valueInCents ? value / 100 : value);

  return (
    <Typography {...other}>
      {parts.map(({type, value}, i) => (
        <span key={i}>{value}</span>
      ))}
    </Typography>
  );
};

export default Currency;
