/**
 * Global application state
 */
import React from "react";
import {Config, config} from "../../config";

const globalContext = React.createContext(config());

export default function GlobalProvider({children, ...extra}: {children: React.ReactNode; [extra: string]: any}): JSX.Element {
  const global = React.useContext(globalContext);
  return <globalContext.Provider value={{...global, ...extra}}>{children}</globalContext.Provider>;
}

export function useGlobal(): Config {
  const global = React.useContext(globalContext);
  if (!global) {
    throw new Error("useGlobal must be used within a GlobalProvider.");
  }
  return global;
};
