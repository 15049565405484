import {Alert, AlertTitle, Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import i18n from "i18next";
import {useMemo} from "react";
import {I18nextProvider, useTranslation} from "react-i18next";
import {Route, Routes, useParams} from "react-router-dom";
import {config} from "../../config";
import Complete from "../page/Complete";
import PaymentPage from "../page/PaymentPage";
import GlobalProvider from "./GlobalProvider";
import {ReactComponent as Stripe} from "../../icons/powered-by-stripe.svg";
import {Provider} from "../../upApi";
import {AppInsightsErrorBoundary, useAppInsightsContext} from "@microsoft/applicationinsights-react-js";

export default function ProviderPage(): JSX.Element {
  const theme = useTheme();
  const {provider} = useParams() as {provider: Provider};
  const isWide = useMediaQuery(theme.breakpoints.up("sm"));
  const providerConfig = useMemo(() => {
    const createdConfig = config(provider);
    console.log(`Config for ${provider}`, createdConfig);
    return createdConfig;
  }, [provider]);
  const {groups, logo, logoBg} = providerConfig;
  const {t} = useTranslation();

  const groupsArray = [groups || []].flat();
  const i18forProvider = i18n.cloneInstance({
    ns: ["translation", ...groupsArray, provider],
    defaultNS: provider,
    fallbackNS: [...groupsArray, "translation"]
  });
  const appInsights = useAppInsightsContext();
  return (
    <I18nextProvider i18n={i18forProvider}>
      <GlobalProvider {...providerConfig}>
        <AppInsightsErrorBoundary
          appInsights={appInsights}
          onError={() => (
            <Box sx={{margin: "auto"}}>
              <Alert severity="error">
                <AlertTitle>{t("App.error.exception.title")}</AlertTitle>
                {t("App.error.exception.text")}
              </Alert>
            </Box>
          )}
        >
          <Grid container sx={{margin: "auto"}} spacing={3} direction={isWide ? "row" : "column"} alignItems={"center"}>
            <Grid
              item
              xs={1}
              sm={6}
              padding={3}
              container
              alignContent="center"
              alignItems={isWide ? "flex-end" : "center"}
              direction="column"
            >
              <Grid item justifySelf="stretch">
                <img src={logo} alt="logo" style={{maxWidth: "100%", height: "10em", margin: "auto", backgroundColor: logoBg}} />
              </Grid>
              <Grid item alignSelf="flex-end">
                <Typography>
                  Payments <Stripe style={{height: "2rem", verticalAlign: "middle"}} />
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Routes>
                <Route path="complete/:status" element={<Complete />} />
                <Route path="" element={<PaymentPage />} />
              </Routes>
            </Grid>
          </Grid>
        </AppInsightsErrorBoundary>
      </GlobalProvider>
    </I18nextProvider>
  );
}
