import {Box, Container, Grid, Link, Paper, Typography} from "@mui/material";
import {configByProvider} from "../../config";
import ThemeContext from "../../ThemeContext";
import GlobalProvider from "../app/GlobalProvider";

const style = {
  textAlign: "center",

  "& .image": {
    height: "100%",
    width: "100%",
    objectFit: "contain"
  },
  "& .caption": {
    textAlign: "center",
    display: "block",
    margin: ".25em"
  },
  "& .button": {
    width: "8em",
    height: "6em",
    padding: "1em",
    transition: "transform .2s linear",
    "&:hover": {
      transform: "scale(1.1)"
    }
  }
};

export default function Index(): JSX.Element {
  return (
    <Container className="root" sx={style}>
      <Typography variant="body1">Adhoc Payments</Typography>
      <Box padding={3}>
        <Grid container spacing={2} justifyContent="center" alignContent="center" alignItems="center">
          {Object.entries(configByProvider).map(([providerSlug, {logoBg, logo}], i) => (
            <GlobalProvider key={i} provider={providerSlug}>
              <ThemeContext>
                <Grid item>
                  <Link href={`/${providerSlug}`}>
                    <Paper className="button" elevation={5} sx={{backgroundColor: logoBg}}>
                      <img className="image" src={`${logo}`} alt={providerSlug} />
                    </Paper>
                  </Link>
                </Grid>
              </ThemeContext>
            </GlobalProvider>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
