import { PaymentMethod } from "./payment";
import { Provider } from "./upApi";

/**
 * Configuration by exports values per environment, as set by REACT_APP_STAGE
 */
import {isArray, union, mergeWith, MergeWithCustomizer, uniq} from "lodash";
export type Environment = "local" | "development" | "test" | "uat" | "production";

export const {name, version} = require("../package.json");
export const stage: Environment = (process.env.REACT_APP_STAGE as Environment) || "local";
export const isDev = process.env.REACT_APP_STAGE !== "production";
export const buildId = process.env.REACT_APP_BUILD_ID;
export const branch = process.env.REACT_APP_BRANCH_NAME;
export const commit = process.env.REACT_APP_COMMIT;
export const publicUrl = process.env.PUBLIC_URL || window.location.origin;
export const clientConfigKey = `config:${name}:${version}`;

// Allow build-time override from variable
const upApiOverride = process.env.REACT_APP_UP_API_URI;

//const silly: [number, (n: number) => string | undefined][] = [[1, (n) => "cool"]].map(([a,b])=>a && b();

type DeepPartial<T> = Partial<{[P in keyof T]: DeepPartial<T[P]>}>;

export type PaymentOptions = {
  minAmountCents: number;
  maxAmountCents: number;
  surcharge?: number;
  isoCurrencyCode: string;
};

export type Config = {
  analytics: {
    appName: string;
    googleAnalyticsId: string;
    instrumentationKey: string;
  };
  buildId: string;
  branch: string;
  stage: Environment;
  commit: string;
  isDev: boolean;
  favicon: string;
  links: {
    home?: string;
  };
  logo: string;
  logoBg: string;
  component: {
    App: {
      showStartPayment: boolean;
    };
    PaymentDetails: PaymentOptions & {
      optionsByMethod: Partial<Record<PaymentMethod, PaymentOptions>>;
    };
  };
  groups?: string | string[];
  paymentMethods: PaymentMethod[];
  provider: Provider;
  upApi: {
    baseUri: string;
  };
};
export type ConfigMap<K extends string> = Partial<Record<K, DeepPartial<Config>>>;
export const configByProvider: ConfigMap<Provider> = {
  "up-international-college-english": {
    links: {},
    logo: "/providers/up/up-education-logo.png",
    logoBg: "#FFF",
    groups: ["nz-country"],
    paymentMethods: ["card"]
  },
  ichm: {
    favicon: "/providers/ichm/ichm-logo.svg",
    logo: "/providers/ichm/ichm-logo.svg",
    logoBg: "#000070",
    groups: ["au-country"],
    paymentMethods: ["card", "au_becs_debit"]
  }
  /*
yoobee: {
  groups: "yoobee-group",
  links: {
    home: "http://yoobee.ac.nz"
  },
  logo: "/providers/yoobee/yoobee-logo.png"
},
nzst: {
  groups: "yoobee-group",
  links: {
    home: "http://nzschooloftourism.co.nz"
  },
  logo: "/providers/nzst/nzst-logo.png"
},
nzma: {
  groups: "nzma-group",
  links: {
    home: "https://www.nzma.ac.nz/"
  },
  logo: "/providers/nzma/nzma-logo.png",
  logoBg: "#FFF"
},
"cut-above": {
  groups: "yoobee-group",
  links: {
    home: "https://www.cutabove.ac.nz/"
  },
  logo: "/providers/cut-above/cut-above-logo.png"
},
elite: {
  groups: "yoobee-group",
  links: {
    home: "https://www.elitebeautyschool.co.nz/"
  },
  logo: "/providers/elite/elite-logo.png"
},
"culinary-collective": {
  groups: "nzma-group",
  links: {
    home: "https://culinarycollective.co.nz"
  },
  logo: "/providers/tcc/tcc-logo.png",
  logoBg: "#FFF"
},
"nz-institute-of-sport": {
  groups: "nzma-group",
  links: {
    home: "https://www.nzis.co.nz/"
  },
  logo: "/providers/nzis/nzis-logo.png",
  logoBg: "#FFF"
},
hanz: {
  groups: "yoobee-group",
  logo: "/providers/hanz/hanz-logo.svg",
  logoBg: "#ab91ce"
}
*/
};

const defaultConfig: DeepPartial<Config> = {
  analytics: {
    appName: process.env.REACT_APP_APP_NAME,
    googleAnalyticsId: "GTM-NK4GLDZ",
    instrumentationKey: process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY
  },
  buildId,
  branch,
  stage,
  commit,
  isDev,
  favicon: "/providers/up/favicon.svg",
  component: {
    App: {
      showStartPayment: false
    },
    PaymentDetails: {
      minAmountCents: 100,
      maxAmountCents: 10000000,
      isoCurrencyCode: "nzd",
      optionsByMethod: {
        card: {
          surcharge: 1.5,
          maxAmountCents: 100000
        }
      }
    }
  }
};

const configByGroup: ConfigMap<string> = {
  // Misc grouped options
  "nzma-group": {
    groups: ["nz-country"]
  },
  "yoobee-group": {
    groups: ["nz-country"]
  },
  "au-country": {
    component: {
      PaymentDetails: {
        isoCurrencyCode: "aud"
      }
    }
  },
  "nz-country": {
    component: {
      PaymentDetails: {
        isoCurrencyCode: "nzd"
      }
    }
  }
};

const configByStage: ConfigMap<Environment> = {
  // Stage specific
  local: {
    upApi: {
      baseUri: upApiOverride || "http://localhost:3030/"
    }
  },

  development: {
    analytics: {
      googleAnalyticsId: "GTM-PRBCKLS"
    },
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-dev.azure-api.net/" //"https://up-mock-api.azurewebsites.net/"
    }
  },

  test: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-test.azure-api.net/"
    },
    links: {}
  },
  uat: {
    upApi: {
      baseUri: upApiOverride || "https://up-int-apim-uat.azure-api.net/"
    },
    links: {}
  },
  production: {
    upApi: {
      baseUri: "https://up-int-apim-prod.azure-api.net/"
    },
    links: {}
  }
};

const customizer: MergeWithCustomizer = function (obj, src) {
  if (isArray(obj)) return union(obj, src); //merge arrays (e.g. exclude,include) as sets
};
function withGroups(config: DeepPartial<Config> = {}) {
  function descendentGroups(group: string): string[] {
    const {groups: children} = configByGroup[group] || {};
    return [group, ...((children && [children].flat()) || []).flatMap((childGroup) => descendentGroups(childGroup as string))];
  }
  const {groups = []} = config;
  const descendants = uniq([groups].flat().flatMap((childGroup) => descendentGroups(childGroup as string)));
  return mergeWith(
    {},
    config,
    ...descendants.map((childGroup) => configByGroup[childGroup] || {}),
    {groups: descendants},
    customizer
  );
}

export function config(provider?: Provider): Config {
  return withGroups(
    mergeWith(defaultConfig, configByStage[stage], provider && configByProvider[provider], {stage, provider}, customizer)
  );
}
