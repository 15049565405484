/**
 * Module for using AppInsights from react hook apps, with React-Router SPA page naviigation
 */
import {ApplicationInsights, ITelemetryItem} from "@microsoft/applicationinsights-web";
import {ReactPlugin} from "@microsoft/applicationinsights-react-js";

/**
 * global appInsights objects
 */

export function useAppInsights({
  appName,
  instrumentationKey
}: {
  appName: string | undefined;
  instrumentationKey: string;
}): ReactPlugin {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: {}
      },
      enableUnhandledPromiseRejectionTracking: true,
      enableRequestHeaderTracking: true,
      disableFetchTracking: true,
      enableAutoRouteTracking: true,
      disableFlushOnBeforeUnload: false,
      disableFlushOnUnload: false
    }
  });
  appInsights.loadAppInsights();
  appName &&
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      if (envelope.tags) envelope.tags["ai.cloud.role"] = appName;
    });
  console.log("AppInsights logging to ", instrumentationKey);
  return reactPlugin;
}
