import { Status } from "../../payment";
import {RestartAltTwoTone} from "@mui/icons-material";
import {Alert, AlertColor, AlertTitle, Button, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {Trans, useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useGlobal} from "../app/GlobalProvider";

export default function Complete(): JSX.Element {
  const {status} = useParams() as {status: Status};
  const {provider} = useGlobal();
  const severityByStatus: Record<Status, AlertColor> = {
    approved: "success",
    cancelled: "info",
    declined: "error"
  };
  const {t} = useTranslation();
  return (
    <Box>
      <Alert
        severity={severityByStatus[status]}
        action={
          <Button variant="contained" startIcon={<RestartAltTwoTone />} href={`/${provider}`}>
            {t("Complete.startOver")}
          </Button>
        }
      >
        <AlertTitle>{t("Complete.title")}</AlertTitle>
        <Typography>
          <Trans i18nKey={`Complete.status.${status}`} />
        </Typography>
      </Alert>
    </Box>
  );
}
